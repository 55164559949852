import React from 'react';
import { FaMobileAlt } from "react-icons/fa";
import { IoCallOutline, IoLogoWhatsapp } from "react-icons/io5";
import { Col, Row } from "react-bootstrap";
import bannerLogo from "../media/maruti-suzuki.svg";

const TopBanner = () => {


    return (
        <>
            <div className={"d-flex justify-content-center text-danger"}>
                Site Under Contruction
            </div>
            <div className="d-flex shadow-sm align-items-center justify-content-between p-20">
                <div style={{ width: "50%" }}>
                    <img src={bannerLogo} alt="" width={200} style={{ alignSelf: "center" }} />
                </div>
                <Row className="d-flex justify-content-end align-items-center" >
                    <Col className="align-items-center w-50 justify-content-end d-flex" xs={12} sm={12} md={6} xl={6} lg={6}> <FaMobileAlt /><IoLogoWhatsapp /><a href="tel:+919910070220">9910070220</a></Col>
                    <Col className="align-items-center w-50 justify-content-end d-flex" xs={12} sm={12} md={6} xl={6} lg={6}><IoCallOutline /><a href="tel:+911244060220">0124-4060220</a> </Col>
                    {/* <FaMobileAlt /><IoCallOutline /><a href="tel:+911244060220">0124-4060220</a>
                <IoCallOutline /><a href="tel:+911244060220">0124-4060220</a> */}
                </Row>
            </div>
        </>
    )
}
export default TopBanner;
