import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/layout';
import HomePage from './pages/home';
import ContactUsPage from './pages/contact-us';
import AboutUsPage from './pages/about-us';
import NotFoundPage from './pages/not-found';

function App() {
  return (
    <React.Fragment>
      <Layout>
        <Switch>
          <Route path="/" component={ContactUsPage} />
          <Route path="/contact-us" component={ContactUsPage} />
          <Route path="/about-us" component={AboutUsPage} />
          <Route path="/home" component={HomePage} />
          <Route path="/not-found" component={NotFoundPage} />
          <Redirect exact from="/" to="/home" />
          <Redirect to="/not-found" />
        </Switch>
      </Layout>

    </React.Fragment>
  );
}

export default App;
