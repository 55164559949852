import React from "react";
import MyNavbar from "./my-navbar";
import TopBanner from "./TopBanner";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Header = () => {
    return (
        <>
            <TopBanner />
            <MyNavbar />
        </>
    );
};

export default Header;
