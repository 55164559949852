import React from "react";
import MapComponent from "../components/contact-us-map";
// import {Map} from "map-my-india-react-new-version";
// import { Map } from 'mapmyindia-react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

const ContactUsPage = () => {
    return (
        <Container fluid={true} className={"min-vw-80"}>
            <Row xs={2} sm={2}>
                <Col xs={12} sm={12} xl={6} lg={6} className={"p-3"}>
                    <span className={"font-weight-bold"}>Rajasthan Auto Works</span>{" "}
                    <br />
          Near Bharat Petrol Pump <br />
          Lt Atul Kataria Marg <br />
          Sheetla Mata Road <br />
          Amanpura, Sector 5 <br />
          Gurugram , 122001 <br />
          Haryana
        </Col>
                <Col xs={12} sm={12} xl={6} lg={6} className={"p-2"}>
                    <MapComponent />
                </Col>
            </Row>
            {" "}
        </Container>
    );
};

export default ContactUsPage;
