import React from "react";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
// import NavbarStyles from "./my-navbar-styles.module.css"
import NavbarStyles from "./my-navbar-styles.module.css";

const MyNavbar = () => {
  return (
    <Navbar bg="light" expand="sm">
      <Navbar.Brand href="/home">Rajasthan Auto Works</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav>
          <NavLink className={NavbarStyles["my-nav-links"]} activeClassName={NavbarStyles["active-my-nav-links"]} style={{ textDecoration: "none" }} to={"/home"} >Home</NavLink>
          <NavLink className={NavbarStyles["my-nav-links"]} activeClassName={NavbarStyles["active-my-nav-links"]} to={"/booking"}>Booking</NavLink>
          <NavLink className={NavbarStyles["my-nav-links"]} activeClassName={NavbarStyles["active-my-nav-links"]} to={"/about-us"}>About Us</NavLink>
          <NavLink className={NavbarStyles["my-nav-links"]} activeClassName={NavbarStyles["active-my-nav-links"]} to={"/contact-us"}>Contact Us</NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavbar;
