import React from 'react'

const HomePage = () => {

    return (
        <div>
            <p>
                This is a home page
            </p>
            <p>
                Rajasthan Auto Works/n
            </p>
            <p>
                (under construction)
            </p>
        </div>
    )
}

export default HomePage;