import React from 'react'

const Footer = () => {

    return (
        <div className={"text-secondary text-center font-weight-light"} style={{ fontSize: "14px" }}>
            created by @kapiljhajhria
        </div>
    )
}

export default Footer;