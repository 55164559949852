import React from "react";

function MapComponent(): JSX.Element {
  return (
    <iframe
      title="Rajasthan Auto Works"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.9712459308744!2d77.02383365097113!3d28.48041258239202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19b7ec07dfed%3A0xa79ee6ff5a5670b!2sRajasthan%20Auto%20Works!5e0!3m2!1sen!2sin!4v1622435979564!5m2!1sen!2sin"
      width="100%"
      style={{ minWidth: "400px" }}
      allowFullScreen={true}
      height="400px"
      loading="lazy"
    ></iframe>
  );
}

export default React.memo(MapComponent);
