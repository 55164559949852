import React from 'react'

const AboutUsPage = () => {

    return (
        <div>
            About us page
        </div>
    )
}

export default AboutUsPage;